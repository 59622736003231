import Icon from "./Icon";

const Address = (props) => (
  <Icon
    defaultSvg={
      <div
        className="inline-svg"
        dangerouslySetInnerHTML={{
          __html: `
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <defs/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#9ed0ff"
                }" d="M423.402 302H87.598L0 512h512zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#73bcff"
                }" d="M512 512H256V302h167.402zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#3aaaff"
                }" d="M403.598 332H107.402L45 482h421zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#0095ff"
                }" d="M466 482H256V332h147.598zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#ff9f00"
                }" d="M256 0c-74.398 0-135 61.598-135 136 0 25.5 7.2 51.398 20.7 73L256 390.2 370.3 209c13.5-21.602 20.7-47.5 20.7-73C391 61.598 330.398 0 256 0zm0 212c-41.398 0-75-34.602-75-76s33.602-76 75-76 75 34.602 75 76-33.602 76-75 76zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#9ed0ff"
                }" d="M177.383 315.14l29.765 3.72-22.496 180.003-29.765-3.718zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#73bcff"
                }" d="M327.266 498.902l-23.075-180 29.77-3.804 23.07 180zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#9ed0ff"
                }" d="M67.5 392h376v30h-376zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#ff7816"
                }" d="M391 136c0 25.5-7.2 51.398-20.7 73L256 390.2V212c41.398 0 75-34.602 75-76s-33.602-76-75-76V0c74.398 0 135 61.598 135 136zm0 0"/>
                <path fill="${
                  props.defaultColor ? props.defaultColor : "#73bcff"
                }" d="M256 392h187.5v30H256zm0 0"/>
                </svg>`,
        }}
      ></div>
    }
    hoveredSvg={
      <div
        className="inline-svg"
        dangerouslySetInnerHTML={{
          __html: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs/>
            <path fill="${
                props.hoverColor ? props.hoverColor : "#9ed0ff"
              }" d="M423.402 302H87.598L0 512h512zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#73bcff"
              }" d="M512 512H256V302h167.402zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#3aaaff"
              }" d="M403.598 332H107.402L45 482h421zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#0095ff"
              }" d="M466 482H256V332h147.598zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#ff9f00"
              }" d="M256 0c-74.398 0-135 61.598-135 136 0 25.5 7.2 51.398 20.7 73L256 390.2 370.3 209c13.5-21.602 20.7-47.5 20.7-73C391 61.598 330.398 0 256 0zm0 212c-41.398 0-75-34.602-75-76s33.602-76 75-76 75 34.602 75 76-33.602 76-75 76zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#9ed0ff"
              }" d="M177.383 315.14l29.765 3.72-22.496 180.003-29.765-3.718zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#73bcff"
              }" d="M327.266 498.902l-23.075-180 29.77-3.804 23.07 180zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#9ed0ff"
              }" d="M67.5 392h376v30h-376zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#ff7816"
              }" d="M391 136c0 25.5-7.2 51.398-20.7 73L256 390.2V212c41.398 0 75-34.602 75-76s-33.602-76-75-76V0c74.398 0 135 61.598 135 136zm0 0"/>
              <path fill="${
                props.hoverColor ? props.hoverColor : "#73bcff"
              }" d="M256 392h187.5v30H256zm0 0"/>
      </svg>`,
        }}
      ></div>
    }
  />
);

export default Address;
