import { colesterolo } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Colesterolo = () => {
  useWow();

  return (
    <div className="colesterolo">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="colesterolo__title">
              <h1>{colesterolo.title}</h1>
            </div>
            <div className="colesterolo__container">
              <p className="colesterolo__container__text wow zoomIn">
                {colesterolo.text.first}
              </p>
              <p className="colesterolo__container__text wow zoomIn">
                {colesterolo.text.second}
              </p>
              <img
                className="wow fadeInLeft colesterolo__container__image"
                src={process.env.PUBLIC_URL + `${colesterolo.image}`}
                alt="colesterolo"
              />
              <p className="colesterolo__container__text wow zoomIn">
                {colesterolo.text.third}
              </p>
              <p className="colesterolo__container__text wow zoomIn">
                {colesterolo.text.fourth}
              </p>
              <p className="colesterolo__container__text wow zoomIn">
                {colesterolo.text.fifth}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Colesterolo;
