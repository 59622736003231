import React from "react";
import useWow from '../../../hooks/useWow'
import { products } from "./data/content.json";
import "./style.scss";

const Chicco = () => {
  useWow()

  return (
    <div className="chicco">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="chicco__logo-container">
              <img
                className="chicco__logo-container__logo"
                src={process.env.PUBLIC_URL + "/images/infanzia/chicco.png"}
                alt="chicco logo"
              />
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="row">
              {products.map((product) => {
                return (
                  <div key={product.id} className="col-12">
                    <h2
                      className={`chicco__block__title chicco__block__title${product.titleClass}`}
                    >
                      {product.title}
                    </h2>

                    <div className="chicco__block">
                      <img
                        className={`chicco__block__image chicco__block__image${product.classname}`}
                        src={process.env.PUBLIC_URL + `${product.image}`}
                        alt={product.alt}
                      />
                      <p className="chicco__block__text wow zoomIn">{product.text.first}</p>
                      <p className="chicco__block__text wow zoomIn">{product.text.second}</p>
                      <p className="chicco__block__text wow zoomIn">{product.text.third}</p>
                      <p className="chicco__block__text wow zoomIn">{product.text.fourth}</p>
                      <p className="chicco__block__text wow zoomIn">{product.text.fifth}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chicco;
