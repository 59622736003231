import Phone from "../../../common/icons/Phone";
import Email from "../../../common/icons/Email";
import Address from "../../../common/icons/Address";
import Whatsapp from "../../../common/icons/Whatsapp";
import Facebook from "../../../common/icons/Facebook";

const details = [
  {
    id: 1,
    url: "mailto:info@farmaciadellelce.com",
    classname: "__email-icon",
    icon: <Email />,
    text: "info@farmaciadellelce.com",
  },
  {
    id: 2,
    url: "tel:+39069981039",
    classname: "__phone-icon",
    icon: <Phone />,
    text: "+39 06 998 1039",
  },
  {
    id: 3,
    url: "",
    classname: "__whatsapp-icon",
    icon: <Whatsapp />,
    text: "Whatsapp",
  },
  {
    id: 4,
    url: "",
    classname: "__facebook-icon",
    icon: <Facebook />,
    text: "Pagina Facebook",
  },
  {
    id: 5,
    url: "",
    classname: "__address-icon",
    icon: <Address />,
    text: "Via dei Faggi n. 1 - Anguillara Sabazia",
  },
];

export default details;
