import React from "react";
import { chisiamo } from "./data/content.json";
import "./style.scss";

const Chisiamo = () => {
  return (
    <div className="chisiamo">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <h1 className="chisiamo__header">{chisiamo.title}</h1>
            <p className="chisiamo__text">
              {chisiamo.text.first}
              <br />
              <br />
              {chisiamo.text.second}
              <br />
              <br />
              {chisiamo.text.third}
              <br />
              <br />
              {chisiamo.text.fourth}
              <br />
              <br />
              {chisiamo.text.fifth}
              <br />
              {chisiamo.text.sixth}
              <br />
              {chisiamo.text.seventh}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chisiamo;
