import * as React from "react";

import Icon from "./Icon";

const Email = (props) => (
  <Icon
    defaultSvg={
      <div
        className="inline-svg"
        dangerouslySetInnerHTML={{
          __html: `
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <defs/>
              <circle cx="256" cy="256" r="256" fill="#5f98d1"/>
              <path fill="${
                props.defaultColor ? props.defaultColor : "#3a6da1"
              }" d="M415.813 147.466H95.558l116.019 120.806 33.48 33.9c-18.216-4.164-19.343-6.759-27.415-13.349-4.234-3.457-12.414-12.852-24.838-25.54L88.217 156.484v198.822l8.265 8.265-.925.963L242.68 511.657c4.412.226 8.852.343 13.32.343 141.385 0 256-114.615 256-256 0-4.246-.11-8.466-.313-12.661l-95.874-95.873z"/>
              <path fill="${
                props.defaultColor ? props.defaultColor : "#fff"
              }" d="M88.217 156.484v198.822l96.958-99.813zM423.783 156.484v198.822l-96.476-99.411zM95.558 147.466h320.255L289.948 278.524a47.506 47.506 0 01-68.524 0L95.558 147.466z"/>
              <path fill="${
                props.defaultColor ? props.defaultColor : "#fff"
              }" d="M297.209 285.496c-10.799 11.244-25.933 17.694-41.523 17.694-15.589 0-30.724-6.448-41.522-17.693l-21.349-22.23-97.257 101.267h320.255l-97.256-101.267-21.348 22.229z"/>
        </svg>`,
        }}
      ></div>
    }
    hoveredSvg={
      <div
        className="inline-svg"
        dangerouslySetInnerHTML={{
          __html: `
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <defs/>
            <circle cx="256" cy="256" r="256" fill="#5f98d1"/>
            <path fill="${
              props.hoverColor ? props.hoverColor : "#3a6da1"
            }" d="M415.813 147.466H95.558l116.019 120.806 33.48 33.9c-18.216-4.164-19.343-6.759-27.415-13.349-4.234-3.457-12.414-12.852-24.838-25.54L88.217 156.484v198.822l8.265 8.265-.925.963L242.68 511.657c4.412.226 8.852.343 13.32.343 141.385 0 256-114.615 256-256 0-4.246-.11-8.466-.313-12.661l-95.874-95.873z"/>
            <path fill="${
              props.hoverColor ? props.hoverColor : "#fff"
            }" d="M88.217 156.484v198.822l96.958-99.813zM423.783 156.484v198.822l-96.476-99.411zM95.558 147.466h320.255L289.948 278.524a47.506 47.506 0 01-68.524 0L95.558 147.466z"/>
            <path fill="${
              props.hoverColor ? props.hoverColor : "#fff"
            }" d="M297.209 285.496c-10.799 11.244-25.933 17.694-41.523 17.694-15.589 0-30.724-6.448-41.522-17.693l-21.349-22.23-97.257 101.267h320.255l-97.256-101.267-21.348 22.229z"/>
      </svg>`,
        }}
      ></div>
    }
  />
);

export default Email;
