import Whatsapp from "../../../../common/icons/Whatsapp";
import Facebook from "../../../../common/icons/Facebook";

const contacts = [
    {
      id: 1,
      url: "https://api.whatsapp.com/send?phone=+393519395458",
      classname: "__whatsapp-icon",
      icon: <Whatsapp />,
      text: "Whatsapp"
    },
    {
      id: 2,
      url: "https://www.facebook.com/farmaciadellelce",
      classname: "__facebook-icon",
      icon: <Facebook />,
      text: "Pagina Facebook"
    },
]

export default contacts