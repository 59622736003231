import React from "react";
import details from "./data/details";
import farmacia from "./farmacia.jpeg";
import "./style.scss";

const Contatti = () => {
  const GOOGLE_MAPS_EMBED_URL =
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.0574811472598!2d12.231459315665454!3d42.0204992792111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f50670a240877%3A0xdcb904a320a57e36!2sVia%20dei%20Faggi%2C%201%2C%2000061%20Anguillara%20Sabazia%20RM%2C%20Italy!5e0!3m2!1sen!2snl!4v1611918236429!5m2!1sen!2snl";

  return (
    <div className="contatti">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1 className="contatti__title">Contatti</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="contatti__vetrina">
              <img
                className="contatti__vetrina__foto"
                src={farmacia}
                alt="vetrina farmacia"
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="contatti__details">
              {details.map((detail) => {
                return (
                  <a
                    key={detail.id}
                    className="contatti__details__link"
                    rel="noreferrer noopener"
                    href={detail.url}
                  >
                    <span
                      className={`contatti__details__link${detail.classname}`}
                    >
                      {detail.icon}
                    </span>
                    <span className="contatti__details__text">
                      {detail.text}
                    </span>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2 className="contatti__dove">Dove siamo</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="contatti__map">
              <iframe
                title="maps"
                src={GOOGLE_MAPS_EMBED_URL}
                width="100%"
                height="100%"
                frameBorder="0"
                allowFullScreen={false}
                aria-hidden="false"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contatti;
