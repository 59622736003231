import React from "react";
import useWow from '../../../hooks/useWow'
import { products } from "./data/products.json";
import "./style.scss";

const Affaticamento = () => {
  useWow()

  return (
    <div className="affaticamento">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="affaticamento__logo-container">
              <h1>Affaticamento e stanchezza</h1>
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="row">
              { products.map((product) => {
                return (
                  <div key={ product.id } className="col-12">
                    <h2
                      className={ `affaticamento__block__title affaticamento__block__title${ product.titleClass }` }
                    >
                      { product.title }
                    </h2>

                    <div className="affaticamento__block">
                      <img
                        className={ `affaticamento__block__image affaticamento__block__image${ product.classname }` }
                        src={ process.env.PUBLIC_URL + `${ product.image }` }
                        alt={ product.alt }
                      />
                      <p className="affaticamento__block__text wow zoomIn">{ product.text.first }</p>
                      <p className="affaticamento__block__text wow zoomIn">{ product.text.second }</p>
                      <p className="affaticamento__block__text wow zoomIn">{ product.text.third }</p>
                      <p className="affaticamento__block__text wow zoomIn">{ product.text.fourth }</p>
                      <p className="affaticamento__block__text wow zoomIn">{ product.text.fifth }</p>
                    </div>
                  </div>
                );
              }) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affaticamento;
