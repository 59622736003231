import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Navigation from "./components/common/Navigation/Navigation";
import Footer from "./components/common/Footer/Footer";
import Infanzia from "./pages/Infanzia/Infanzia";
import Chicco from "./pages/Infanzia/Chicco/Chicco";
import Philips from "./pages/Infanzia/Philips/Philips";
import Fiocchi from "./pages/Infanzia/Fiocchi/Fiocchi";
import Babygella from "./pages/Infanzia/Babygella/Babygella";
import Babystar from "./pages/Infanzia/Babystar/Babystar";
import Pampers from "./pages/Infanzia/Pampers/Pampers";
import Mellin from "./pages/Infanzia/Mellin/Mellin";
import Euphidra from "./pages/Infanzia/Euphidra/Euphidra";
import Parafarmaco from "./pages/Parafarmaco/Parafarmaco";
import Affaticamento from "./pages/Parafarmaco/Affaticamento/Affaticamento";
import Vitamine from "./pages/Parafarmaco/Vitamine/Vitamine";
import Disordini from "./pages/Parafarmaco/Disordini/Disordini";
import Difese from "./pages/Parafarmaco/Difese/Difese";
import Sonno from "./pages/Parafarmaco/Sonno/Sonno";
import Dermocosmesi from "./pages/Dermocosmesi/Dermocosmesi";
import Creme from "./pages/Dermocosmesi/Creme/Creme";
import MakeUp from "./pages/Dermocosmesi/Makeup/MakeUp";
import Autoanalisi from "./pages/Autoanalisi/Autoanalisi";
import Glicemia from "./pages/Autoanalisi/Glicemia/Glicemia";
import Colesterolo from "./pages/Autoanalisi/Colesterolo/Colesterolo";
import Emoglobina from "./pages/Autoanalisi/Emoglobina/Emoglobina";
import Chisiamo from "./pages/Chisiamo/Chisiamo";
import Contatti from "./pages/Contatti/Contatti";

const App = () => {
  return (
    <div className="page-container">
      <Navigation />
      <div className="content-wrap">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Infanzia" component={Infanzia} />
          <Route path="/Infanzia/Chicco" component={Chicco} />
          <Route path="/Infanzia/Philips" component={Philips} />
          <Route path="/Infanzia/Fiocchi" component={Fiocchi} />
          <Route path="/Infanzia/Babygella" component={Babygella} />
          <Route path="/Infanzia/Babystar" component={Babystar} />
          <Route path="/Infanzia/Pampers" component={Pampers} />
          <Route path="/Infanzia/Mellin" component={Mellin} />
          <Route path="/Infanzia/Euphidra" component={Euphidra} />
          <Route exact path="/Parafarmaco" component={Parafarmaco} />
          <Route path="/Parafarmaco/Affaticamento" component={Affaticamento} />
          <Route path="/Parafarmaco/Vitamine" component={Vitamine} />
          <Route path="/Parafarmaco/Disordini" component={Disordini} />
          <Route path="/Parafarmaco/Difese" component={Difese} />
          <Route path="/Parafarmaco/Sonno" component={Sonno} />
          <Route exact path="/Dermocosmesi" component={Dermocosmesi} />
          <Route path="/Dermocosmesi/Creme" component={Creme} />
          <Route path="/Dermocosmesi/MakeUp" component={MakeUp} />
          <Route exact path="/Autoanalisi" component={Autoanalisi} />
          <Route path="/Autoanalisi/Glicemia" component={Glicemia} />
          <Route path="/Autoanalisi/Colesterolo" component={Colesterolo} />
          <Route path="/Autoanalisi/Emoglobina" component={Emoglobina} />
          <Route exact path="/Chisiamo" component={Chisiamo} />
          <Route exact path="/Contatti" component={Contatti} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default App;
