import { product } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const MakeUp = () => {
  useWow();

  return (
    <div className="makeup">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="makeup__title">
              <h2>Make Up</h2>
              <p className=" mt-2 makeup__text wow zoomIn">{ product.text }</p>
            </div>
            <div className="makeup__container">
              <p className="makeup__container__text wow zoomIn">Vieni a trovarci in farmacia per scoprire tutti i prodotti dei
                migliori brand in circolazione</p>
              <div className="row">
                <div className="col-12">
                  <div className="makeup__container__image-container">
                    <img
                      className="wow zoomIn makeup__container__image-container__image mb-3 mt-3"
                      src={ process.env.PUBLIC_URL + `${ product.image1 }` }
                      alt="avene"
                    />
                    <img
                      className="wow zoomIn makeup__container__image-container__image mb-3 mt-3"
                      src={ process.env.PUBLIC_URL + `${ product.image2 }` }
                      alt="rimmel"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeUp;
