import React from "react";
import { Link } from "react-router-dom";
import useWow from "../../hooks/useWow";
import { Carousel } from "react-bootstrap";
import { carouselImages } from "./data/carouselImages.json";
import { cards } from "./data/cards.json";
import "./style.scss";

const Home = () => {
  useWow();

  return (
    <div className="home">
      <Carousel pause={ false } className="home__carousel">
        { carouselImages.map((image) => {
          return (
            <Carousel.Item key={ image.id } className="home__carousel__item">
              <Link to={ image.to }>
                <img
                  className="d-block w-100 home__carousel__item__image"
                  src={ process.env.PUBLIC_URL + `${ image.path }` }
                  alt={ image.alt }
                />
                <Carousel.Caption className="home__carousel__item__caption">
                  <h2 className="home__carousel__item__caption__title">
                    { image.title }
                  </h2>
                  <p className="home__carousel__item__caption__text">
                    { image.text }
                  </p>
                </Carousel.Caption>
              </Link>
            </Carousel.Item>
          );
        }) }
      </Carousel>
      <div className="home__cards">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <div className="row">
                { cards.map((card) => {
                  return (
                    <div key={ card.id } className="col-12 col-md-4">
                      <Link to={ card.to }>
                        <div className="card wow zoomIn home__card">
                          <img
                            className="card-img-top home__card__image"
                            src={ process.env.PUBLIC_URL + `${ card.path }` }
                            alt={ card.alt }
                          />
                          <div className="card-body home__card__body">
                            <h5 className="card-title home__card__body__title mb-3">
                              { card.title }
                            </h5>
                            <p className="card-text home__card__body__text">{ card.text }</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                    ;
                }) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
