import React from "react";
import contacts from "./data/contacts";
import { payments } from "./data/payments.json";
import { links } from "./data/links.json";
import "./style.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-4">
            <h2 className="footer__header">Socials</h2>
            <div className="footer__ciao">
              {contacts.map((contact) => {
                return (
                  <a
                    key={contact.id}
                    className="footer__ciao__link"
                    rel="noreferrer noopener"
                    href={contact.url}
                  >
                    <span
                      className={`footer__ciao__link${contact.classname}`}
                    >
                      {contact.icon}
                    </span>
                    <span className="footer__ciao__text">
                      {contact.text}
                    </span>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="col-12 col-md-4">
            <h2 className="footer__header">Puoi pagare con</h2>
            <div className="footer__payments">
              {payments.map((payment) => {
                return (
                  <img
                    key={payment.id}
                    className={`footer__payments${payment.classname}`}
                    src={process.env.PUBLIC_URL + `${payment.path}`}
                    alt={payment.alt}
                  />
                );
              })}
            </div>
            <div className="footer__fisco">
              <span>P.IVA: 12139471002 &nbsp;</span>
              <span>REA: RM - 1359121</span>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <h2 className="footer__header">Orari di apertura</h2>
            <div className="footer__open">
              <div className="footer__open__container">
                <p className="footer__open__container__day">Lunedí - Sabato</p>
                <p className="footer__open__container__time">8:30 - 20:30</p>
              </div>
              <div className="footer__open__container">
                <p className="footer__open__container__day">Domenica</p>
                <p className="footer__open__container__time">9:00 - 13-00</p>
              </div>
            </div>
            <h2 className="footer__header">Link Utili</h2>
            <div className="footer__links">
              <div className="footer__links__container">
                {links.map((link) => {
                  return (
                    <a key={link.id} href={link.url}>
                      <p className="footer__links__container__link">
                        {link.text}
                      </p>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          Copyright 2021. All rights reserved. Developed by &nbsp;
          <a
            className="footer__copyright__name"
            href="mailto:emanueledibene@gmail.com"
          >
            Emanuele Di Bene
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
