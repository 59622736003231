import React from "react";
import classnames from "classnames";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { fiocchi, cards } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Fiocchi = () => {
  useWow();
  const { width } = useWindowWidth();

  return (
    <div className="fiocchi">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="fiocchi__logo-container">
              <img
                className="fiocchi__logo-container__logo"
                src={process.env.PUBLIC_URL + "/images/infanzia/fiocchi.png"}
                alt="fiocchi logo"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="fiocchi__title">
              <h1>{fiocchi.title}</h1>
            </div>
            <div className="fiocchi__container">
              <p className="fiocchi__container__text wow zoomIn">
                {fiocchi.text.first}
              </p>
              <p className="fiocchi__container__text wow zoomIn">
                {fiocchi.text.second}
              </p>
              <img
                className="wow zoomIn fiocchi__container__image"
                src={process.env.PUBLIC_URL + `${fiocchi.image}`}
                alt="fiocchi"
              />
              <p className="fiocchi__container__text wow zoomIn">
                {fiocchi.text.third}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="fiocchi__title">
              <h2>{cards.introduction.title}</h2>
            </div>
            <div className="fiocchi__container">
              <img
                className="wow zoomIn fiocchi__container__image-talco"
                src={process.env.PUBLIC_URL + `${cards.introduction.image}`}
                alt="talco fiocchi di riso"
              />
              <div className="row">
                {cards.blocks.map((block) => {
                  return (
                    <div
                      key={block.id}
                      className={classnames(
                        width > 768
                          ? `col-12 col-md-4 fiocchi__container__block ${block.classname}`
                          : `col-12 col-md-4 fiocchi__container__block`
                      )}
                    >
                      <h4 className="fiocchi__container__block__title">
                        {block.title}
                      </h4>
                      <p>{block.text}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fiocchi;
