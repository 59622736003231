import React from "react";
import { babystar } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Babystar = () => {
  useWow();

  return (
    <div className="babystar">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="babystar__logo-container">
              <img
                className="babystar__logo-container__logo"
                src={process.env.PUBLIC_URL + "/images/infanzia/babystar.png"}
                alt="babystar logo"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="babystar__title">
              <h1>{babystar.title}</h1>
            </div>
            <div className="babystar__container">
              {babystar.blocks.map((block) => {
                return (
                  <div key={block.id}>
                    <h2 className="babystar__container__sub-title">
                      {block.subtitle}
                    </h2>
                    <p className="babystar__container__text wow zoomIn">
                      {block.text}
                    </p>
                    <img
                      className="wow fadeInRight babystar__container__image"
                      src={process.env.PUBLIC_URL + `${block.image}`}
                      alt="babystar"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Babystar;
