import React from "react";
import { products } from "./data/products.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Sonno = () => {
  useWow();

  return (
    <div className="sonno">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="sonno__title">
              <h1>Disturbi del Sonno</h1>
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="sonno__container">
              {products.blocks.map((block) => {
                return (
                  <div key={block.id}>
                    <h2 className='sonno__container__sub-title'>{block.subtitle}</h2>
                    <p className="sonno__container__text wow zoomIn">
                      {block.text}
                    </p>
                    <img
                      className="wow zoomIn sonno__container__image"
                      src={process.env.PUBLIC_URL + `${block.image}`}
                      alt={block.alt}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sonno;
