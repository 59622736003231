import { useEffect } from "react";
import WOW from "wowjs";

export default function useWow() {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);
}
