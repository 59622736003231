import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { menuItems } from "./data/menuItems.json";
import SubMenuToggleIcon from "../../../common/icons/SubMenuToggleIcon";

import "./style.scss";

const $ = require("jquery");
function Navigation() {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);

  const __openNavigation = () => {
    setIsNavigationOpen(true);

    $(document.body).addClass("prevent-scroll");
  };

  const __closeNavigation = () => {
    setIsNavigationOpen(false);

    $(document.body).removeClass("prevent-scroll");
  };

  const __toggleMobileMenuItem = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let el = $(e.target);

    if (el.hasClass("navigation__overlay__list__item__open-close")) {
      $(e.target).parent().parent().toggleClass("active");
    } else {
      $(e.target)
        .closest(".navigation__overlay__list__item__open-close")
        .parent()
        .parent()
        .toggleClass("active");
    }
  };

  const location = useLocation();

  return (
    <nav className="navigation">
      <Link to="/" className="navigation__header">
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="logo"
          className="navigation__header__logo"
        ></img>
        <h1 className="navigation__header__title">Farmacia dell'Elce</h1>
        <h4 className="navigation__header__sub-title">
          Dr. Bevilacqua Carlo Maria
        </h4>
      </Link>
      <button
        className={classnames(
          "navigation__toggle-button",
          isNavigationOpen && "navigation__toggle-button--hide-background"
        )}
        onClick={() => {
          isNavigationOpen ? __closeNavigation() : __openNavigation();
        }}
      >
        <div className="navigation__toggle-button__line navigation__toggle-button__line--line-1"></div>
        <div className="navigation__toggle-button__line navigation__toggle-button__line--line-2"></div>
        <div className="navigation__toggle-button__line navigation__toggle-button__line--line-3"></div>
      </button>
      <div className="navigation__menu">
        <ul className="navigation__menu__list">
          {menuItems.map((menuItem) => {
            return (
              <li
                key={menuItem.id}
                className={classnames(
                  "navigation__menu__list__item",
                  (location.pathname === menuItem.path ||
                    menuItem.path.split("/")[1] ===
                      location.pathname.split("/")[1]) &&
                    "navigation__menu__list__item--active"
                )}
              >
                <Link
                  className="navigation__menu__list__item__link"
                  to={menuItem.path}
                >
                  {menuItem.title}
                </Link>
                {menuItem.children && menuItem.children.length > 0 && (
                  <ul id="dropdown" className="navigation__menu__sub-list">
                    {menuItem.children.map((sublink) => {
                      return (
                        <li
                          className={classnames(
                            "navigation__menu__sub-list__item",
                            sublink.path === location.pathname &&
                              "navigation__menu__sub-list__item--active"
                          )}
                          key={sublink.id}
                        >
                          <Link
                            className="navigation__menu__sub-list__item__link"
                            to={sublink.path}
                          >
                            {sublink.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      <div
        className={classnames(
          "navigation__overlay",
          isNavigationOpen && "navigation__overlay--active"
        )}
      >
        <ul className="navigation__overlay__list">
          {menuItems.map((menuItem) => {
            return (
              <li
                key={menuItem.id}
                className={classnames(
                  "navigation__overlay__list__item",
                  menuItem.children &&
                    menuItem.children.length > 0 &&
                    "navigation__overlay__list__item--has-dropdown",
                  (location.pathname === menuItem.path ||
                    menuItem.path.split("/")[1] ===
                      location.pathname.split("/")[1]) &&
                    "navigation__overlay__list__item--active"
                )}
              >
                <Link
                  className="navigation__overlay__list__item__link"
                  onClick={() => {
                    __closeNavigation();
                  }}
                  to={menuItem.path}
                >
                  {menuItem.title}
                  {menuItem.children && menuItem.children.length > 0 && (
                    <button
                      onClick={__toggleMobileMenuItem}
                      className={classnames(
                        "navigation__overlay__list__item__open-close"
                      )}
                    >
                      <SubMenuToggleIcon className="navigation__overlay__list__item__open-close__icon" />
                    </button>
                  )}
                </Link>

                {menuItem.children && menuItem.children.length > 0 && (
                  <ul className="navigation__overlay__sub-list">
                    {menuItem.children.map((sublink) => {
                      return (
                        <li
                          className={classnames(
                            "navigation__overlay__sub-list__item",
                            sublink.path === location.pathname &&
                              "navigation__overlay__sub-list__item--active"
                          )}
                          key={sublink.id}
                        >
                          <Link
                            className="navigation__overlay__sub-list__item__link"
                            onClick={() => {
                              __closeNavigation();
                            }}
                            to={sublink.path}
                          >
                            {sublink.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
}

export default Navigation;
