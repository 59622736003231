import React from "react";
import useWow from "../../../hooks/useWow";
import { introduction, products } from "./data/content.json";
import "./style.scss";

const Mellin = () => {
  useWow();

  return (
    <div className="mellin">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="mellin__container">
              <img
                className="mellin__container__logo"
                src={process.env.PUBLIC_URL + "/images/infanzia/mellin.jpg"}
                alt="mellin logo"
              />
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 ">
            <div className="row">
              <div className="col-12">
                <h1 className="mellin__introduction__title">
                  {introduction.title}
                </h1>
                <p className="mellin__introduction__text">
                  {introduction.text}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="row">
              {products.map((product) => {
                return (
                  <div key={product.id} className="col-12">
                    <h2
                      className={`mellin__block__title mellin__block__title${product.titleClass}`}
                    >
                      {product.title}
                    </h2>

                    <div className="mellin__block">
                      <img
                        className={`mellin__block__image mellin__block__image${product.classname}`}
                        src={process.env.PUBLIC_URL + `${product.image}`}
                        alt={product.alt}
                      />
                      <p className="mellin__block__text wow zoomIn">
                        {product.text.first}
                      </p>
                      <p className="mellin__block__text wow zoomIn">
                        {product.text.second}
                      </p>
                      <p className="mellin__block__text wow zoomIn">
                        {product.text.third}
                      </p>
                      <p className="mellin__block__text wow zoomIn">
                        {product.text.fourth}
                      </p>
                      <p className="mellin__block__text wow zoomIn">
                        {product.text.fifth}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mellin;
