import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useWow from "../../hooks/useWow";
import { sections } from "./data/content.json";
import { cards } from "./data/cards.json";
import useWindowWidth from "../../hooks/useWindowWidth";
import "./style.scss";
import classnames from "classnames";

const Autoanalisi = () => {
  useWow();
  const { width } = useWindowWidth();
  const [ showClass, setShowClass ] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if ( width < 768 ) {
        setShowClass(!showClass)
      }
    }, 3000);
    return () => {
      clearInterval(interval)
    };
  }, [ width, showClass ]);


  return (
    <div className="autoanalisi">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <h1 className="autoanalisi__header">{ sections.title }</h1>
            <h4 className="autoanalisi__subheader">{ sections.subtitle }</h4>
            <p className="autoanalisi__text">
              { sections.text.first }
              <br/>
              { sections.text.second }
              <br/>
              { sections.text.third }
              <br/>
              <br/>
            </p>
            <p className="autoanalisi__text autoanalisi__text__last">{ sections.text.fourth }</p>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <div className="autoanalisi__container">
              <div className="row">
                { cards.map((card) => {
                    return (
                      <div
                        key={ card.id }
                        className="col-12 col-md-6 col-xl-4">
                        <Link to={ card.to }>
                          <div
                            className={ classnames("flip-card mb-5", `${ card.classname }`, showClass ? 'responsive' : ''
                            ) }>
                            <div className={ classnames("flip-card-inner", showClass ? 'responsive' : '') }>
                              <div className="flip-card-front">
                                <img
                                  className=
                                    "flip-card-front__image"
                                  src={ process.env.PUBLIC_URL + `${ card.path }` }
                                  alt={ card.alt }
                                />
                              </div>
                              <div className="flip-card-back">
                                <p className="flip-card-back__text">
                                  { card.title }
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  }
                ) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
    ;
};

export default Autoanalisi;
