import React from "react";
import useWow from '../../../hooks/useWow'
import { products } from "./data/products.json";
import "./style.scss";

const Disordini = () => {
  useWow()

  return (
    <div className="disordini">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="disordini__logo-container">
              <h1>Disordini Gastrointestinali</h1>
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="row">
              {products.map((product) => {
                return (
                  <div key={product.id} className="col-12">
                    <h2
                      className={`disordini__block__title disordini__block__title${product.titleClass}`}
                    >
                      {product.title}
                    </h2>

                    <div className="disordini__block">
                      <img
                        className={`disordini__block__image disordini__block__image${product.classname}`}
                        src={process.env.PUBLIC_URL + `${product.image}`}
                        alt={product.alt}
                      />
                      <p className="disordini__block__text wow zoomIn">{product.text.first}</p>
                      <p className="disordini__block__text wow zoomIn">{product.text.second}</p>
                      <p className="disordini__block__text wow zoomIn">{product.text.third}</p>
                      <p className="disordini__block__text wow zoomIn">{product.text.fourth}</p>
                      <p className="disordini__block__text wow zoomIn">{product.text.fifth}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disordini;
