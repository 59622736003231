import { glicemia } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Glicemia = () => {
  useWow();

  return (
    <div className="glicemia">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="glicemia__title">
              <h1>{glicemia.title}</h1>
            </div>
            <div className="glicemia__container">
              <p className="glicemia__container__text wow zoomIn">
                {glicemia.text.first}
              </p>
              <img
                className="wow flipInX glicemia__container__image"
                src={process.env.PUBLIC_URL + `${glicemia.image}`}
                alt="glicemia"
              />
              <p className="glicemia__container__text wow zoomIn">
                {glicemia.text.second}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Glicemia;
