import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useWow from "../../hooks/useWow";
import { products } from "./data/products.json";
import { parafarmaco } from "./data/content.json";
import classnames from "classnames";
import useWindowWidth from "../../hooks/useWindowWidth";
import "./style.scss";

const Parafarmaco = () => {
  useWow();
  const { width } = useWindowWidth();
  const [ showClass, setShowClass ] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if ( width < 768 ) {
        setShowClass(!showClass)
      }
    }, 3000);
    return () => {
      clearInterval(interval)
    };
  }, [ width, showClass ]);

  return (
    <div className="parafarmaco">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <h1 className="parafarmaco__header">{ parafarmaco.title }</h1>
            <p className="parafarmaco__text">
              { parafarmaco.text.first }
              <br/>
              { parafarmaco.text.second }
              <br/>
              <br/>
            </p>
            <p className="parafarmaco__text parafarmaco__text__last">
              { parafarmaco.text.fifth }
            </p>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="parafarmaco__container">
              <div className="row">
                { products.map((product) => {
                  return (
                    <div key={ product.id } className="col-12 col-md-6 col-lg-4">
                      <Link to={ product.to }>
                        <div
                          className={ classnames(
                            "parafarmaco__container__image",
                            width < 1024 ? "" : `${ product.classname }`,
                            showClass ? 'parafarmaco__container__image__overlay-responsive' : ''
                          ) }
                          style={ { backgroundImage: `url('${ product.path }')` } }
                        >
                          <div className="parafarmaco__container__image__overlay">
                            <div className="parafarmaco__container__image__overlay__description">
                              { product.description }
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                }) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parafarmaco;
