import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import useWindowWidth from "../../hooks/useWindowWidth";
import { dermocosmesi } from "../Dermocosmesi/data/content.json";

const Dermocosmesi = () => {
  const { width } = useWindowWidth();
  const [ showLeft, setShowLeft ] = useState(false)
  const [ showRight, setShowRight ] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if ( width < 768 ) {
        setShowLeft(!showLeft)
        setTimeout(() => {
          setShowRight(!showRight)
        }, 2000)
      }
    }, 3000);
    return () => {
      clearInterval(interval)
    };
  }, [ width, showLeft, showRight ]);

  return (
    <div className="dermocosmesi">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <h1 className="dermocosmesi__header">{ dermocosmesi.title }</h1>
            <p className="dermocosmesi__text">
              { dermocosmesi.text.first }
              <br/>
              { dermocosmesi.text.second }
              <br/>
              { dermocosmesi.text.third }
              <br/>
              { dermocosmesi.text.fourth }
              <br/>
              <br/>
            </p>
            <p className="dermocosmesi__text dermocosmesi__text__last">
              { dermocosmesi.text.fifth }
            </p>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="dermocosmesi__container">
              <img alt="dermocosmesi" className="dermocosmesi__container__image"
                   src={ process.env.PUBLIC_URL + '/images/dermocosmesi/creme/dermocosmesi.jpg' }/>
              <Link to="/Dermocosmesi/Creme">
                <div className="dermocosmesi__container__image__overlay-right"
                     style={ { opacity: showRight ? '1' : '' } }>
                  <div className="dermocosmesi__container__image__overlay-right__description">
                    Creme per il viso e per il corpo
                  </div>
                </div>
              </Link>
              <Link to="/Dermocosmesi/MakeUp">
                <div
                  className="dermocosmesi__container__image__overlay-left" style={ { opacity: showLeft ? '1' : '' } }>
                  <div className="dermocosmesi__container__image__overlay-left__description">
                    Make Up
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dermocosmesi;