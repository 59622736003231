import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import useWindowWidth from "../../hooks/useWindowWidth";
import useWow from "../../hooks/useWow";
import { products } from "./data/products.json";
import { infanzia } from "./data/content.json";

import "./style.scss";

const Infanzia = () => {
  useWow();
  const { width } = useWindowWidth();

  return (
    <div className="infanzia">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <h1 className="infanzia__header">{infanzia.title}</h1>
            <p className="infanzia__text">
              {infanzia.text.first}
              <br />
              {infanzia.text.second}
              <br />
              {infanzia.text.third}
              <br />
              {infanzia.text.fourth}
              <br />
              <br />
            </p>
            <p className="infanzia__text infanzia__text__last">{infanzia.text.fifth}</p>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="infanzia__container">
              <div className="row">
                {products.map((product) => {
                  return (
                    <div
                      key={product.id}
                      className="col-12 col-sm-4 col-md-6 col-lg-3"
                    >
                      <Link to={product.to}>
                        <img
                          className={classnames(
                            "infanzia__container__image",
                            width < 768
                              ? `${product.classname[0].sm}`
                              : `${product.classname[1].md}`
                          )}
                          src={process.env.PUBLIC_URL + `${product.path}`}
                          alt={product.alt}
                        />
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infanzia;
