import React from "react";
import useWow from "../../../hooks/useWow";
import useWindowWidth from "../../../hooks/useWindowWidth";
import { philips, products } from "./data/content.json";
import "./style.scss";

const Philips = () => {
  useWow();
  const { width } = useWindowWidth();

  return (
    <div className="philips">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="philips__container">
              <img
                className="philips__container__logo"
                src={process.env.PUBLIC_URL + "/images/infanzia/philips.png"}
                alt="philips logo"
              />
              <h1 className="philips__container__title">{philips.title}</h1>
              {width > 768 ? (
                <img
                  className="philips__container__top-image"
                  src={process.env.PUBLIC_URL + `${philips.image}`}
                  alt="philips logo"
                />
              ) : (
                <img
                  className="philips__container__top-image"
                  src={process.env.PUBLIC_URL + `${philips.imageSmall}`}
                  alt="philips logo"
                />
              )}
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="row">
              {products.map((product) => {
                return (
                  <div key={product.id} className="col-12">
                    <h2
                      className={`philips__block__title philips__block__title${product.titleClass}`}
                    >
                      {product.title}
                    </h2>

                    <div className="philips__block">
                      <img
                        className={`philips__block__image philips__block__image${product.classname}`}
                        src={process.env.PUBLIC_URL + `${product.image}`}
                        alt={product.alt}
                      />
                      <p className="philips__block__text wow zoomIn">
                        {product.text.first}
                      </p>
                      <p className="philips__block__text wow zoomIn">
                        {product.text.second}
                      </p>
                      <p className="philips__block__text wow zoomIn">
                        {product.text.third}
                      </p>
                      <p className="philips__block__text wow zoomIn">
                        {product.text.fourth}
                      </p>
                      <p className="philips__block__text wow zoomIn">
                        {product.text.fifth}
                      </p>
                      <p className="philips__block__text wow zoomIn">
                        {product.text.sixth}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Philips;
