import { products } from "./data/products.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Difese = () => {
  useWow();

  return (
    <div className="difese">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="difese__title">
              <h1>Difese Immunitarie</h1>
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="difese__container">
              { products.map((product) => {
                return (
                  <div key={ product.id }>
                    <h2 className='difese__container__sub-title'>{ product.subtitle }</h2>
                    <img
                      className="wow zoomIn difese__container__image"
                      src={ process.env.PUBLIC_URL + `${ product.image }` }
                      alt={ product.alt }
                    />
                    <p className="difese__container__text wow zoomIn">
                      { product.text.first }
                    </p>
                    <p className="difese__container__text wow zoomIn">
                      { product.text.second }
                    </p>
                    <p className="difese__container__text wow zoomIn">
                      { product.text.third }
                    </p>
                    <p className="difese__container__text wow zoomIn">
                      { product.text.fourth }
                    </p>
                    <p className="difese__container__text wow zoomIn">
                      { product.text.fifth }
                    </p>
                  </div>
                );
              }) }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Difese;
