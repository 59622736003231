import React from "react";
import { babygella } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Babygella = () => {
  useWow();

  return (
    <div className="babygella">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="babygella__logo-container">
              <img
                className="babygella__logo-container__logo"
                src={process.env.PUBLIC_URL + "/images/infanzia/babygella.png"}
                alt="chicco logo"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="babygella__title">
              <h1>{babygella.title}</h1>
            </div>
            <div className="babygella__container">
              <p className="babygella__container__text wow zoomIn">
                {babygella.text.first}
              </p>
              <p className="babygella__container__text wow zoomIn">
                {babygella.text.second}
              </p>
              <img
                className="wow flipInX babygella__container__image"
                src={process.env.PUBLIC_URL + `${babygella.image}`}
                alt="babygella"
              />
              <p className="babygella__container__text wow zoomIn">
                {babygella.text.third}
              </p>
              <p className="babygella__container__text wow zoomIn">
                {babygella.text.fourth}
              </p>
              <p className="babygella__container__text wow zoomIn">
                {babygella.text.fifth}
              </p>
              <p className="babygella__container__text wow zoomIn">
                {babygella.text.sixth}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Babygella;
