import * as React from "react";
import classnames from "classnames";

import "./style.scss";

class Icon extends React.PureComponent {
  render() {
    const { defaultSvg, hoveredSvg, ...otherProps } = this.props;

    return (
      <div
        {...otherProps}
        className={classnames(
          "site-icon",
          this.props.hoveredSvg && "site-icon--with-hover",
          this.props.className
        )}
      >
        <div className="site-icon__svg">{defaultSvg}</div>
        <div className="site-icon__svg-hovered">
          {this.props.hoveredSvg != null ? hoveredSvg : defaultSvg}
        </div>
      </div>
    );
  }
}

export default Icon;
