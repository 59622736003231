import React from "react";
import { pampers } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Pampers = () => {
  useWow();

  return (
    <div className="pampers">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="pampers__container">
              <img
                className="pampers__container__logo"
                src={process.env.PUBLIC_URL + "/images/infanzia/pampers.jpg"}
                alt="pampers logo"
              />
            </div>
          </div>
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="pampers__container">
              {pampers.blocks.map((block) => {
                return (
                  <div key={block.id}>
                    <h2 className='pampers__container__sub-title'>{block.subtitle}</h2>
                    <p className="pampers__container__text wow zoomIn">
                      {block.text}
                    </p>
                    <img
                      className="wow zoomIn pampers__container__image"
                      src={process.env.PUBLIC_URL + `${block.image}`}
                      alt="pampers"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pampers;
