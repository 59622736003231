import React from "react";
import { euphidra } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Euphidra = () => {
  useWow();

  return (
    <div className="euphidra">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="euphidra__logo-container">
              <img
                className="euphidra__logo-container__logo"
                src={process.env.PUBLIC_URL + "/images/infanzia/euphidra.png"}
                alt="euphidra logo"
              />
            </div>
          </div>{" "}
        </div>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="wow zoomIn euphidra__title">
              <h1>{euphidra.title}</h1>
              <h4 className="wow zoomIn euphidra__sub-title">{euphidra.subtitle}</h4>
            </div>
            <div className="euphidra__container">
              <img
                className="wow flipInX euphidra__container__image"
                src={process.env.PUBLIC_URL + `${euphidra.image}`}
                alt="euphidra"
              />
              <p className="euphidra__container__text wow zoomIn">
                {euphidra.text.introduction}
              </p>
              <ol>
                {euphidra.text.list.map((titles) => {
                  return (
                    <li key={titles.id} className="wow zoomIn euphidra__container__list">
                      {titles.suggestion}
                    </li>
                  );
                })}
              </ol>
              {euphidra.blocks.map((block) => {
                return (
                  <div key={block.id}>
                    <h2 className="wow zoomIn euphidra__container__block__title">
                      {block.title}
                    </h2>
                    {block.image && (
                      <img
                        className="wow zoomIn euphidra__container__block__image"
                        src={process.env.PUBLIC_URL + `${block.image}`}
                        alt="euphidra"
                      />
                    )}
                    <p className="wow zoomIn">{block.text.introduction}</p>
                    <ul>
                      {block.text.list &&
                        block.text.list.map((part) => {
                          return (
                            <li className="wow zoomIn euphidra__container__block__list" key={part.id}>
                              {part.section}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Euphidra;
