import React from "react";
import classnames from "classnames";
import { products } from "./data/content.json";
import useWow from "../../../hooks/useWow";
import "./style.scss";

const Creme = () => {
  useWow();

  return (
    <div className="creme">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="creme__title">
              <h2>Creme</h2>
            </div>
            <div className="creme__container">
              <div className="row">
                { products.map((product) => {
                  return (
                    <div
                      key={ product.id }
                      className={ classnames(
                        "col-12", ` ${ product.classname }`
                      ) }
                    >
                      <img
                        className="wow zoomIn creme__container__image mb-3 mt-3"
                        src={ process.env.PUBLIC_URL + `${ product.image }` }
                        alt="creme viso e corpo"
                      />
                      <p className="creme__container__text wow zoomIn">{ product.text.first }</p>
                      <p className="creme__container__text wow zoomIn">{ product.text.second }</p>
                      <p className="creme__container__text wow zoomIn">{ product.text.third }</p>
                      <p className="creme__container__text wow zoomIn">{ product.text.fourth }</p>
                      <p className="creme__container__text wow zoomIn">{ product.text.fifth }</p>
                    </div>
                  );
                }) }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Creme;
